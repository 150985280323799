@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeError {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spinner-circle-rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes scaleIn {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}
