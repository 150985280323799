@font-face {
  font-family: 'LatoBlack';
  src: url('../../assets/fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'LatoRegular';
  src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
}

$general-font-semi: "Open Sans Semibold", sans-serif;
$general-font-reg: "Open Sans Semibold", sans-serif;
$general-color: #272E3F;

// Gray colors
$gray-01: #333333;
$gray-02: #4f4f4f;
$gray-03: #828282;
$gray-04: #bdbdbd;
$gray-05: #e0e0e0;
$gray-06: #f2f2f2;

// Brand colors
$brand-green: #00a046;
$brand-white: #FFFFFF;
$brand-black: #000000;

// Other colors
$errors: #a91712;

// Colors other brands
$brand-facebook: #1F76DF;

// Primary buttons colors
$hover-btn-default: #00B04D;
$pressed-btn-default: #00C255;
$disabled-btn-default: #5DD08F;


// Secondary buttons colors (will be changed)
$primary-btn-secondary: #FFFFFF;
$hover-btn-secondary: #F8E5E5;
$pressed-btn-secondary: #BD0000;
$disabled-btn-secondary: #ECB3B3;

// Переменные для размеров экранов
$XS: 480px;
// Переменные для размеров экранов
$mobile-xs: 320px;  // Extra Small Mobile
$mobile-sm: 321px;  // Small Mobile
$mobile-md: 481px;  // Standard Mobile
$tablet: 768px;     // Tablets
$laptop: 1025px;    // Small Laptops & Large Tablets
$desktop: 1281px;   // Desktops & Large Screens

// Миксины для медиа-запросов
@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile-sm {
    @media screen and (max-width: $mobile-md) {
      @content;
    }
  } /*@else if $breakpoint == mobile-sm {
    @media screen and (min-width: $mobile-sm) and (max-width: $mobile-md - 1) {
      @content;
    }
  }*/ @else if $breakpoint == mobile-md {
    @media screen and (min-width: $mobile-md) and (max-width: $tablet - 1) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media screen and (min-width: $tablet) and (max-width: $laptop - 1) {
      @content;
    }
  } @else if $breakpoint == laptop {
    @media screen and (min-width: $laptop) and (max-width: $desktop - 1) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media screen and (min-width: $desktop) {
      @content;
    }
  }
}

@mixin respond-to-multiple($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @include respond-to($breakpoint) {
      @content;
    }
  }
}

@mixin bg-green-rgba($opacity: 0.5) {
  background-color: rgba(0, 160, 70, $opacity);
}


// BUTTONS
@mixin primary-buttons {
  // TODO: Deprecated
  border-radius: 2px;
  color: $brand-white;
  border: 1px solid transparent;
  cursor: pointer;

  &:active {
    color: $brand-green;
    background-color: $brand-white;
    border: 1px solid $brand-green;
  }
}

@mixin default-buttons($isBold: false, $isTransparent: false, $bg:null, $c:null) {
  border-radius: 4px;
  transition: all 300ms;

  @if ($isBold) {
    font-weight: 500;
  }

  @if ($isTransparent) {
    color: $brand-green;
    background-color: $brand-white;
    border: 2px solid $brand-green;

  } @else {
    color: $brand-white;
    background-color: $brand-green;
    border: 1px solid transparent;
  }

  @if ($bg and $c) {
    background-color: $bg;
    color: $c;
  }

  &:hover {
    @if ($isTransparent) {
      color: $brand-white;
    }

    background-color: $hover-btn-default;
  }

  &:active {
    background-color: $pressed-btn-default;
  }

  &:disabled {
    background-color: $disabled-btn-default;
    border: 1px solid transparent;
    cursor: default;
    pointer-events: none;
  }
}

@mixin default-inputs() {
  border: 1px solid $gray-04;
  background-color: $brand-white;
  color: $general-color;

  & + .error {
    display: none;
    color: $errors;
    font-size: 12px;
    line-height: 15px;
    height: 16px;
  }

  &:hover {
    border: 1px solid $brand-green;
    outline: 1px solid $brand-green;
  }

  &:focus {
    border: 1px solid $brand-green;
    outline: 1px solid $brand-green;

    @include bg-green-rgba(0.05);
  }

  &:not(:focus).ng-dirty.ng-invalid {
    border: 1px solid $errors;
    outline: 1px solid $errors;
    color: $errors;
    margin-bottom: 0;

    & + .error {
      display: block;
    }
  }

  &:disabled{
    background-color: $gray-06;
    border: 1px solid $gray-04;
    color: $gray-04;
    cursor: default;
    pointer-events: none;
  }
}


// Typography
@mixin font-set {
  font-style: normal;
  font-family: $general-font-reg;
  color: $general-color;
}

@mixin large-title {
  @include font-set;

  font-weight: bold;
  font-size: 24px;
  line-height: 150.84%;
}

@mixin title-1 {
  @include font-set;

  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
}

@mixin title-2 {
  @include font-set;

  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

@mixin title-3 {
  @include font-set;

  font-weight: 600;
  font-size: 18px;
  line-height: 143.3%;
}


@mixin subtitle-1 {
  @include font-set;

  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

@mixin subtitle-2 {
  @include font-set;

  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

@mixin subtitle-1-link {
  @include font-set;

  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  text-decoration: underline;
}


@mixin highlite-body-text {
  @include font-set;

  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
}

@mixin highlite-text-link {
  @include font-set;

  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

@mixin highlite-subtitle {
  @include font-set;

  font-weight: 600;
  font-size: 16px;
  line-height: 155.9%;
}

@mixin highlite-small-text {
  @include font-set;

  font-weight: normal;
  font-size: 12px;
  line-height: 160.69%;
}


@mixin body-text {
  @include font-set;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

@mixin text-link {
  @include font-set;

  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
}

@mixin small-text {
  @include font-set;

  font-weight: 300;
  font-size: 12px;
  line-height: 160.69%;
}


@mixin links {
  font-style: normal;
  color: $brand-green;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  text-decoration-line: underline;
}

@mixin tab-name {
  @include font-set;

  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    cursor: pointer;
    z-index: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #e0e0e0;
    z-index: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: $brand-green;
    border-radius: 3px;
    z-index: 0;
  }
}

@mixin no-highlight {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin multiline-text-ellipsis($line: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

// Position
@mixin flex-center-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin absolute-center {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

// Shadows
@mixin shadow-filter {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.15);
}

// Global behaviour for user events
@mixin user-activity {
  &:hover {
    filter: grayscale(100%);
  }

  &:active {
    filter: brightness(80%);
  }
}

// tools

@mixin demo-text {
  width: fit-content;
  height: 40px;
  @include absolute-center
}

// Effects
@mixin ripple($main-color: white) {

  background-position: center;
  transition: background 0.8s;

  // did not figure out how to make it work with svg...
  //&:hover {
  //  background: $main-color radial-gradient(circle, transparent 1%, $main-color 1%) center/15000%;
  //}

  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
}

// Cards
@mixin mobile-card-size($screenWidth, $cardWidth, $colGap) {
  @media (max-width: $screenWidth) {
    ::ng-deep {
      app-property-default-card {
        width: calc($cardWidth - $colGap);

        .object-item {
          width: 100%;
          max-width: unset;

          .default-size {
            width: 100%;
          }
        }
      }
    }
  }
}

// Loader
@mixin pending {
  .preloader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;

    .sp-circle {
      width: 40px;
      height: 40px;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid rgba($brand-green, 1);
      border-bottom-color: rgba($brand-green, 0.25);
    }
  }
}
