@import "assets/styles/reset";
@import "assets/styles/variables";
@import "assets/styles/listing-type";
@import "assets/styles/map";
@import "assets/styles/animation";

@font-face {
  font-family: 'LatoBlack';
  src: url('assets/fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
}

app-base-auth {
  display: block;
  height: 100%;
  background: #353535 !important;
}

.logo {
  @include respond-to(mobile-sm) {
    justify-content: start;
  }

  cursor: pointer;
  font-family: "LatoBlack", sans-serif;
  font-size: 45px;
  line-height: 45px;
  color: $brand-green;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #A91712;
    font-size: 12px;
    left: -24px;
    top: -14px;
    position: relative;
    display: block;
  }

  .beta {
    font-size: 12px;
    font-weight: 100;
    color: #FFFFFF;
    background-color: $brand-green;
    padding: 4px 8px;
    border-radius: 3px;
    line-height: 11px;
    height: 18px;
    position: relative;
    top: 9px;
    left: -15px;
  }

  .local {
    background-color: darkred;
  }

  .dev {
    background-color: black
  }

  .stage {
    background-color: darkorange;
  }
}

.logo-footer {
  background-repeat: no-repeat;
}

app-test-page {
  display: block;
  height: 800px;
}

app-checkbox {
  display: inherit;
}

ngb-typeahead-window {
  width: 100%;
  margin: 0;
  border-radius: 0;
  border: 0;
  padding: 0;

  button {
    padding: 16px !important;

    &.active {
      background: #e5e5e5 !important;
    }
  }
}

.dropdown-menu {
  border-radius: 0;
  padding: 0;
  margin: 0;
  z-index: 1001;
}

.dropdown-item {
  border: 0;
  border-bottom: 1px solid #c0c2c2;
}

.modal-backdrop {
  background: #000;
  opacity: 0.7;
}

.modal-dialog {
  top: 10%;
}

.modal-dialog,
.modal-content {
  width: max-content !important;
}

.modal-content {
  background: #ffffff;
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
}

.modal-content-close {
  position: absolute;
  width: 12px;
  height: 12px;
  background-size: contain;
  background: white url("assets/image/close-popup.svg") no-repeat;
  top: 16px;
  right: 16px;
  border: 0;
}

button {
  &.submit {
    width: 100%;
    background: $brand-green;
    margin-bottom: 16px;
    border: none;
    color: white;
    padding: 13px;
    text-align: center;

    &:disabled {
      opacity: 0.7;
    }
  }
}

app-slider {
  position: relative;
}

app-profile {
  display: flex;
}

app-map,
app-saved-searches,
app-favourites,
app-account,
app-setting {
  width: 100%;
}

.sp {
  width: 15px;
  height: 15px;
  clear: both;
  margin-right: 10px;
}

.sp-circle {
  display: block;
  position: absolute;
  left: 25px;
  border: 2px solid rgba(white, 1);
  border-bottom-color: rgba(white, 0.25);
  border-radius: 50%;
  animation: spinner-circle-rotation 0.9s infinite linear;
}

.spinner-large {
  width: 45px;
  height: 45px;
  top: 45%;
  left: 45%;
  position: absolute;
  border-radius: 100%;
  z-index: 999;
  animation: rotation 2s infinite linear;
  border: 3px solid;
  border-right-color: transparent;
}

.pagination {
  margin-top: 5px;

  .page-item.disabled .page-link {
    background-color: transparent;
    display: none;
  }

  .page-link {
    @include highlite-small-text;

    width: 30px;
    height: 30px;
    color: $brand-green;
    margin-right: 5px;
    border: 0;
    border-radius: 50px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(189, 0, 0, 0.25);
    }

    &:hover {
      box-shadow: 0 0 0 0.1rem rgba(189, 0, 0, 0.25);
    }
  }

  .page-item:first-child .page-link {
    border-radius: 50px;
    border: 1px solid $brand-green;
  }

  .page-item:last-child .page-link {
    border-radius: 50px;
    border: 1px solid $brand-green;
  }

  .page-item.active .page-link {
    background-color: $brand-green;
    border: 1px solid $brand-green;
    color: $brand-white;
  }
}

.blur {
  filter: blur(8px);

  @include no-highlight;
}

.hide-price {
  filter: blur(2px);
}

.close-modal-details {
  border-radius: 2px;
  color: #FFFFFF;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  background-image: url("/assets/image/icons/go-search-page.svg");
  width: 24px;
  height: 24px;
  right: 20%;
  top: 95px;
  position: absolute;
  z-index: 999;
}

.toggle-button {
  width: 40px;
  height: 22px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid $brand-green;
  justify-content: flex-end;

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: $brand-green;
    margin: 1px;
  }
}

.toggle-button.active {
  background-color: $brand-green;
  justify-content: flex-start;

  .circle {
    background-color: $brand-white;
  }
}

.modal-cluster {
  border-radius: 0;
}

ngb-datepicker {
  z-index: 9999;
  top: 5px !important;
  border: 1px solid $brand-green !important;
  color: $brand-green !important;
  border-radius: 2px !important;

  .btn-light {
    border-radius: 2px;
  }

  .btn-link, .ngb-dp-weekday, .btn-light {
    color: $brand-green !important;
  }

  .btn-light.active, .bg-primary {
    color: $brand-white !important;
    background-color: $brand-green !important;
  }
}

.cal-today {
  background-color: rgba(255, 5, 4, 0.1) !important;
}

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn 300ms;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.matched-text {
  font-weight: bold;
}

.options-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-right: 4px;
    border-right: 1px solid $gray-04;

    span {
      padding: 0 4px 0 2px;
    }

    &:last-of-type {
      border-right: 0
    }
  }
}

.required-field {
  position: relative;

  &:after {
    position: absolute;
    content: '*';
    color: $errors;
    margin-left: 2px;
  }
}

.p-slider-range, .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: $brand-green;
}

.p-slider-handle {
  border-color: $brand-green;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: initial;
  }
}

.leaflet-popup {
  @include respond-to-multiple(mobile-xs, mobile-sm) {
    //transform: translate3d(0px, 139px, 0px) !important;
    //top: 30%;
    left: -130px !important;
  }
}