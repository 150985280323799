$sale: #E10504;
$lease: #1cafdd;
$sold: #00A150;
$leased: #00A150;
$price-change: #FF9A30;
$taken-off-market: #4F4F4F;

.sale {
  background-color: $sale;
}

.rent {
  background-color: $lease;
}

.sold {
  background-color: $sold;
}

.leased {
  background-color: $leased;
}

.price-change {
  background-color: $price-change;
}

.taken-off-market {
  background-color: $taken-off-market;
}

.sale-map {
  background-color: $sale;

  &:before {
    border-top-color: $sale;
  }
}

.lease-map {
  background-color: $lease;

  &:before {
    border-top-color: $lease;
  }
}

.sold-map {
  background-color: $sold;

  &:before {
    border-top-color: $sold;
  }
}

.leased-map {
  background-color: $leased;

  &:before {
    border-top-color: $leased;
  }
}

.price-change-map {
  background-color: $price-change;

  &:before {
    border-top-color: $price-change;
  }
}

.taken-off-market-map {
  background-color: $taken-off-market;

  &:before {
    border-top-color: $taken-off-market;
  }
}
