@import "variables";

html,
body {
  @include scroll-bar;

  height: 100%;
  width: 100%;
}

*:focus {
  outline: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
   color: $brand-black
}

button {
  cursor: pointer;
}
